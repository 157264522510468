
import AdminBackupDisplayComponent from '@/components/admin/AdminBackupDisplayComponent.vue';
import AdminBackupRequestComponent from '@/components/admin/AdminBackupRequestComponent.vue';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: { AdminBackupDisplayComponent, AdminBackupRequestComponent },

  setup() {
    const store = useStore();

    const operationsList = computed(() => {
      return store.getters['adminModule/publicInfrastructureJobs'];
    });

    async function requestBackupHandler(input: any) {
      await store.dispatch('adminModule/createBackupRequest', input);
    }

    async function requestRestoreHandler(input: any) {
      await store.dispatch('adminModule/createRestoreRequest', input);
    }

    async function requestPublicJobs() {
      await store.dispatch('adminModule/getPublicInfrustructureJobs');
    }

    return {
      operationsList,
      requestBackupHandler,
      requestRestoreHandler,
      requestPublicJobs
    };
  }
});
