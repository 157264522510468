import { classToPlain, plainToClass } from 'class-transformer';

export class InfrastructureBackupIntegrationEvent {
  payload!: { bucket_export_path: string };
  type = 'FirestoreBackupRequestedEvent';

  // eslint-disable-next-line
  static fromJson(payload?: any): InfrastructureBackupIntegrationEvent {
    return plainToClass(InfrastructureBackupIntegrationEvent, payload);
  }

  toJson(): any {
    return classToPlain(this);
  }
}

export class InfrastructureRestoreIntegrationEvent {
  payload!: {
    bucket_export_path: string;
    bucket_import_path: string;
    collection_drop_ids: string[];
  };
  type = 'FirestoreRestoreRequestedEvent';

  // eslint-disable-next-line
  static fromJson(payload?: any): InfrastructureRestoreIntegrationEvent {
    return plainToClass(InfrastructureRestoreIntegrationEvent, payload);
  }

  toJson(): any {
    return classToPlain(this);
  }
}
