
import { computed, defineComponent } from 'vue';
import ZeroLandingComponent from '../ZeroLandingComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

export default defineComponent({
  components: { ZeroLandingComponent, ButtonComponent },
  props: {
    operationsList: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const cleanOperationsList = computed(() => {
      return props.operationsList.map((operation: any) => {
        if (!operation.payload) {
          return {
            payload: {
              status: 'Pending',
              name: 'Pending',
              type: 'Pending',
              date: 'Pending'
            }
          };
        }
        if (!operation.payload.name) {
          operation.payload.name = 'Pending';
        }
        if (!operation.payload.status) {
          operation.payload.status = 'Pending';
        }
        if (!operation.payload.type) {
          operation.payload.type = 'Pending';
        }
        if (operation.payload.date && operation.payload.date != 'Pending') {
          operation.payload.date = operation.payload.date.toString();
        } else {
          operation.payload.date = 'Pending';
        }

        return operation;
      });
    });

    function requestPublicJobs() {
      emit('requestPublicJobs');
    }
    return { cleanOperationsList, requestPublicJobs };
  }
});
