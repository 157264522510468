import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_admin_backup_display_component = _resolveComponent("admin-backup-display-component")
  const _component_admin_backup_request_component = _resolveComponent("admin-backup-request-component")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_admin_backup_display_component, {
      operationsList: _ctx.operationsList,
      onRequestPublicJobs: _ctx.requestPublicJobs
    }, null, 8, ["operationsList", "onRequestPublicJobs"]),
    _createVNode(_component_admin_backup_request_component, {
      onRequestBackup: _ctx.requestBackupHandler,
      onRequestRestore: _ctx.requestRestoreHandler
    }, null, 8, ["onRequestBackup", "onRequestRestore"])
  ], 64))
}