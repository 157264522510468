
import { defineComponent, ref } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import {
  InfrastructureBackupIntegrationEvent,
  InfrastructureRestoreIntegrationEvent
} from '../../models/InfrastructureIntegrationEvent';

export default defineComponent({
  components: { ButtonComponent },
  props: {},
  setup(_, { emit }) {
    const backupPath = ref('');
    const importPath = ref('');

    function requestBackup() {
      if (backupPath.value) {
        const event = InfrastructureBackupIntegrationEvent.fromJson({
          payload: {
            bucket_export_path: backupPath.value
          }
        });
        emit('requestBackup', event);
      }
    }

    function requestRestore() {
      if (backupPath.value && importPath.value) {
        const event = InfrastructureRestoreIntegrationEvent.fromJson({
          payload: {
            bucket_export_path: backupPath.value,
            bucket_import_path: importPath.value,
            collection_drop_ids: [
              'class',
              'course',
              'event',
              'permission',
              'student'
            ]
          }
        });
        emit('requestRestore', event);
      }
    }

    return { importPath, backupPath, requestBackup, requestRestore };
  }
});
